import React, {useEffect, useState} from 'react';
import {Header} from 'semantic-ui-react';
import forumService from "../services/forumService";
import {useParams} from "react-router-dom";
import ThreadDetail from "../components/ThreadDetail";

const ThreadPage = () => {

    const [replies, setReplies] = useState([]);
    const [thread, setThread] = useState(null)
    const [newReply, setNewReply] = useState(null)
    const [loading, setLoading] = useState(true);
    const [nextPaginationToken, setNextPaginationToken] = useState(null);
    const [paginationTokenStack, setPaginationTokenStack] = useState([]);
    const {threadId} = useParams()
    const forumId = 'forum0'

    useEffect(() => {
        const refreshReplies = async () => {
            const {thread, replies, nextPaginationToken} = await forumService.getThreadReplies({forumId, threadId})
            setThread(thread)
            setReplies(replies);
            setNextPaginationToken(nextPaginationToken);
            setLoading(false);
        }

        void refreshReplies()
    }, [newReply, threadId]);

    const nextPage = async () => {
        setLoading(true);
        const {replies, nextPaginationToken: newNextPaginationToken} = await forumService.getThreadReplies({forumId, threadId, nextPaginationToken});
        setPaginationTokenStack([...paginationTokenStack, nextPaginationToken]);
        setNextPaginationToken(newNextPaginationToken);
        setReplies(replies);
        setLoading(false);
    };

    const previousPage = async () => {
        setLoading(true);
        const {replies, nextPaginationToken: newNextPaginationToken} = await forumService.getThreadReplies({
            forumId,
            threadId,
            nextPaginationToken: paginationTokenStack[paginationTokenStack.length - 1]
        });
        setPaginationTokenStack(paginationTokenStack.slice(0, -1));
        setNextPaginationToken(newNextPaginationToken);
        setReplies(replies);
        setLoading(false);
    };

    const content = loading ? (<div>Loading...</div>) : (
        <ThreadDetail
            forumId={forumId}
            thread={thread}
            replies={replies}
            setNewReply={setNewReply}
            nextPage={nextPage}
            previousPage={previousPage}
            nextPaginationToken={nextPaginationToken}
            paginationTokenStack={paginationTokenStack}
        />
    );

    return (
        <div>
            <Header as="h1">Forum</Header>
            {content}
        </div>
    );
};

export default ThreadPage;
