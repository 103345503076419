import React from 'react';
import {List, Button, Divider, Message} from 'semantic-ui-react';
import NewReply from "./NewReply";

const ThreadDetail = ({ forumId, thread, replies, setNewReply, nextPage, previousPage, nextPaginationToken, paginationTokenStack }) => {

    const replyElements = replies.map(reply => (
        <List.Item key={reply.id} className={'thread-list-item'}>
            <List.Content>
                <Message
                    size='mini'
                    icon='reply'
                    header={reply.authorId}
                    content={reply.replyBody}
                />
            </List.Content>
        </List.Item>
    ));

    return (
        <div>
            <Message
                size='small'
                icon='comment'
                header={thread.threadTitle + " - " + thread.authorId}
                content={thread.threadBody}
            />
            <div>
                <List divided relaxed>
                    {replyElements}
                </List>
                <Button disabled={paginationTokenStack.length < 1} basic style={{margin: "1em 0 1em 0"}} onClick={previousPage}><i className="angle double left icon"></i>Older</Button>
                <Button disabled={!nextPaginationToken} basic style={{margin: "1em 0 1em 0"}} onClick={nextPage}>Newer<i className="angle double right icon"></i></Button>
                <Divider horizontal>-</Divider>
                <NewReply forumId={forumId} threadId={thread.id} setNewReply={setNewReply} />
            </div>
        </div>
    );
};

export default ThreadDetail;
