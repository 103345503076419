import { Amplify, Auth} from 'aws-amplify';

const awsconfig = {
    aws_cognito_region: 'eu-west-1',

    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: 'eu-west-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: "ForumAPI",
                // TODO what's this?
                endpoint: "https://r32u0cehyd.execute-api.eu-west-1.amazonaws.com"
            }
        ]
    },
    Storage: {
        AWSS3: { // TODO inject env vars with the region
            bucket: `${process.env.REACT_APP_PROJECT_NAME}-uploads-bucket`, //REQUIRED -  Amazon S3 bucket name
            region: 'eu-west-1', //OPTIONAL -  Amazon service region
        }
    }
};

const config = {
    apiGateway: {
        invokeUrl: process.env.REACT_APP_INVOKE_URL
    }
}

Amplify.configure(awsconfig);
// Amplify.Logger.LOG_LEVEL = 'DEBUG';

export { Amplify, Auth, config};