import React, {useContext, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import './ForumPage.css';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import LeafletPage from './pages/LeafletPage';
import LoginPage from './pages/LoginPage';
import AccountPage from './pages/AccountPage';
import AdminPage from './pages/AdminPage';
import ProtectedRoute from './components/ProtectedRoute';
import ForumPage from "./pages/ForumPage";
import ThreadPage from "./pages/ThreadPage";
import {Container, Message} from "semantic-ui-react";
import UserContext from "./contexts/UserContext";
import NotificationContext from "./contexts/NotificationContext";
import MainBreadcrumb from "./components/MainBreadcrumb";
import DocumentsPage from "./pages/DocumentsPage";

function App() {
    const {load} = useContext(UserContext);
    const {notificationVisible, message, style, clearNotification} = useContext(NotificationContext);

    useEffect(() => {
        void load();
    }, []);

    const messageStyleAttributes = (style) => {
        const attrs = {
            onDismiss: () => clearNotification()
        };
        if (style === "info") return {...attrs, info: true};
        else if (style === "warning") return {...attrs, warning: true};
        else return attrs;
    };

    const notification = notificationVisible ? (
        <Message {...messageStyleAttributes(style)}>
            <Message.Header>{message}</Message.Header>
        </Message>
    ) : null;

    return (
        <Router>
            <Navbar/>
            <Container>
                <MainBreadcrumb icon='right angle'/>
            </Container>
            <Container className="main-container">
                {notification}

                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/leaflet" element={<LeafletPage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/account" element={
                        <ProtectedRoute redirectTo="/login">
                            <AccountPage/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/admin" element={
                        <ProtectedRoute redirectTo="/login">
                            <AdminPage/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/forum" element={
                        <ProtectedRoute redirectTo="/login">
                            <ForumPage/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/documents" element={
                        <ProtectedRoute redirectTo="/login">
                            <DocumentsPage/>
                        </ProtectedRoute>
                    }/>
                    <Route path="/forum/thread/:threadId" element={
                        <ProtectedRoute redirectTo="/login">
                            <ThreadPage/>
                        </ProtectedRoute>
                    }/>
                </Routes>
            </Container>
        </Router>
    );
}

export default App;